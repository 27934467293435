import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _232e9828 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _301b35ba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c6c711c6 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _19a5b6b7 = () => interopDefault(import('../pages/oskar-courses/index.vue' /* webpackChunkName: "pages/oskar-courses/index" */))
const _e6e65e36 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _7c4af210 = () => interopDefault(import('../pages/unauthenticated-signing-page/index.vue' /* webpackChunkName: "pages/unauthenticated-signing-page/index" */))
const _15c7fec1 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _1f6e3c3c = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _32a1849a = () => interopDefault(import('../pages/auth/renew-password.vue' /* webpackChunkName: "pages/auth/renew-password" */))
const _ae21939e = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _1bbdb028 = () => interopDefault(import('../pages/auth/switch-account.vue' /* webpackChunkName: "pages/auth/switch-account" */))
const _403e032d = () => interopDefault(import('../pages/dashboard/bank-accounts.vue' /* webpackChunkName: "pages/dashboard/bank-accounts" */))
const _04914e2e = () => interopDefault(import('../pages/dashboard/landlords.vue' /* webpackChunkName: "pages/dashboard/landlords" */))
const _572f6b3e = () => interopDefault(import('../pages/dashboard/notifications.vue' /* webpackChunkName: "pages/dashboard/notifications" */))
const _26c64166 = () => interopDefault(import('../pages/dashboard/properties.vue' /* webpackChunkName: "pages/dashboard/properties" */))
const _99582406 = () => interopDefault(import('../pages/dashboard/suppliers.vue' /* webpackChunkName: "pages/dashboard/suppliers" */))
const _727a315f = () => interopDefault(import('../pages/dashboard/tenants.vue' /* webpackChunkName: "pages/dashboard/tenants" */))
const _67bee778 = () => interopDefault(import('../pages/dashboard/unpaid-costs.vue' /* webpackChunkName: "pages/dashboard/unpaid-costs" */))
const _52c99f12 = () => interopDefault(import('../pages/dashboard/unpaid-rent.vue' /* webpackChunkName: "pages/dashboard/unpaid-rent" */))
const _16d0e502 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _79750b3d = () => interopDefault(import('../pages/onboarding/confirm_invitation.vue' /* webpackChunkName: "pages/onboarding/confirm_invitation" */))
const _7367cfe8 = () => interopDefault(import('../pages/onboarding/demo.vue' /* webpackChunkName: "pages/onboarding/demo" */))
const _cfeb8176 = () => interopDefault(import('../pages/settings/company-details.vue' /* webpackChunkName: "pages/settings/company-details" */))
const _666195d4 = () => interopDefault(import('../pages/swan/callback.vue' /* webpackChunkName: "pages/swan/callback" */))
const _5177e4b2 = () => interopDefault(import('../pages/swan/consent-callback.vue' /* webpackChunkName: "pages/swan/consent-callback" */))
const _a81b7cb8 = () => interopDefault(import('../pages/dashboard/accounting/listing.vue' /* webpackChunkName: "pages/dashboard/accounting/listing" */))
const _20b653b3 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-accounts.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-accounts" */))
const _657c675a = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entries.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entries" */))
const _2b78dc59 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entry-sets.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entry-sets" */))
const _93b6762e = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-journals.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-journals" */))
const _1b4083f5 = () => interopDefault(import('../pages/viewer/CGU/cgu.vue' /* webpackChunkName: "pages/viewer/CGU/cgu" */))
const _169e6d46 = () => interopDefault(import('../pages/workflow/account-statement/create.vue' /* webpackChunkName: "pages/workflow/account-statement/create" */))
const _06b445fa = () => interopDefault(import('../pages/workflow/landlord/create.vue' /* webpackChunkName: "pages/workflow/landlord/create" */))
const _447faf36 = () => interopDefault(import('../pages/workflow/property/create.vue' /* webpackChunkName: "pages/workflow/property/create" */))
const _39c9bc0e = () => interopDefault(import('../pages/workflow/supplier/create.vue' /* webpackChunkName: "pages/workflow/supplier/create" */))
const _0fb92030 = () => interopDefault(import('../pages/workflow/tenant/create.vue' /* webpackChunkName: "pages/workflow/tenant/create" */))
const _7c26a332 = () => interopDefault(import('../pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index.vue' /* webpackChunkName: "pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index" */))
const _352b6c68 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/index" */))
const _0cf3abfc = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/index" */))
const _d19fc49e = () => interopDefault(import('../pages/viewer/file/_fileId.vue' /* webpackChunkName: "pages/viewer/file/_fileId" */))
const _3f3cba99 = () => interopDefault(import('../pages/viewer/inventory/_inventoryId.vue' /* webpackChunkName: "pages/viewer/inventory/_inventoryId" */))
const _5d7e15e2 = () => interopDefault(import('../pages/viewer/management-report/_managementReportId.vue' /* webpackChunkName: "pages/viewer/management-report/_managementReportId" */))
const _28492c19 = () => interopDefault(import('../pages/viewer/mandate/_mandateId.vue' /* webpackChunkName: "pages/viewer/mandate/_mandateId" */))
const _13de39d9 = () => interopDefault(import('../pages/viewer/payment/_paymentId.vue' /* webpackChunkName: "pages/viewer/payment/_paymentId" */))
const _e8bd982a = () => interopDefault(import('../pages/viewer/rent/_rentId.vue' /* webpackChunkName: "pages/viewer/rent/_rentId" */))
const _314f09fe = () => interopDefault(import('../pages/viewer/rental/_rentalId.vue' /* webpackChunkName: "pages/viewer/rental/_rentalId" */))
const _0eedf764 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/documents" */))
const _25034421 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/informations" */))
const _737dad0f = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/management-reports" */))
const _2e8a99e6 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/properties.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/properties" */))
const _2c3f59e2 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/documents" */))
const _1717d3e6 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/informations" */))
const _2d247b15 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/payment-methods" */))
const _6442700e = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rentals.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rentals" */))
const _727ce8f8 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rents" */))
const _440be7c5 = () => interopDefault(import('../pages/workflow/property/_propertyId/cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/cost/create" */))
const _57cb0ed8 = () => interopDefault(import('../pages/workflow/property/_propertyId/inventory/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/inventory/create" */))
const _df85a4b6 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/create.old.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/create.old" */))
const _c0793a98 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/form.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/form" */))
const _0cae20d9 = () => interopDefault(import('../pages/workflow/property/_propertyId/rent/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rent/create" */))
const _0d938364 = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/create" */))
const _cfcdb738 = () => interopDefault(import('../pages/workflow/property/_propertyId/tenant-cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/tenant-cost/create" */))
const _633ac3c1 = () => interopDefault(import('../pages/viewer/property/_propertyId/cost/_costId.vue' /* webpackChunkName: "pages/viewer/property/_propertyId/cost/_costId" */))
const _1c2f6629 = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index" */))
const _e3c5cb66 = () => interopDefault(import('../pages/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/landlord/_landlordId/index" */))
const _f03efc12 = () => interopDefault(import('../pages/property/_propertyId/index.vue' /* webpackChunkName: "pages/property/_propertyId/index" */))
const _90c7c7b6 = () => interopDefault(import('../pages/supplier/_supplierId/index.vue' /* webpackChunkName: "pages/supplier/_supplierId/index" */))
const _01ca1461 = () => interopDefault(import('../pages/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/tenant/_tenantId/index" */))
const _037737b3 = () => interopDefault(import('../pages/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/landlord/_landlordId/documents" */))
const _22b21a9c = () => interopDefault(import('../pages/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/landlord/_landlordId/informations" */))
const _525b096c = () => interopDefault(import('../pages/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/landlord/_landlordId/management-reports" */))
const _36a506c8 = () => interopDefault(import('../pages/landlord/_landlordId/payouts.vue' /* webpackChunkName: "pages/landlord/_landlordId/payouts" */))
const _0c0b3944 = () => interopDefault(import('../pages/landlord/_landlordId/update.vue' /* webpackChunkName: "pages/landlord/_landlordId/update" */))
const _c9024674 = () => interopDefault(import('../pages/property/_propertyId/contracts.vue' /* webpackChunkName: "pages/property/_propertyId/contracts" */))
const _ae126d2a = () => interopDefault(import('../pages/property/_propertyId/costs.vue' /* webpackChunkName: "pages/property/_propertyId/costs" */))
const _188aba5d = () => interopDefault(import('../pages/property/_propertyId/documents.vue' /* webpackChunkName: "pages/property/_propertyId/documents" */))
const _41ae617f = () => interopDefault(import('../pages/property/_propertyId/rents.vue' /* webpackChunkName: "pages/property/_propertyId/rents" */))
const _38a3f0f4 = () => interopDefault(import('../pages/property/_propertyId/update.vue' /* webpackChunkName: "pages/property/_propertyId/update" */))
const _b9045478 = () => interopDefault(import('../pages/supplier/_supplierId/informations.vue' /* webpackChunkName: "pages/supplier/_supplierId/informations" */))
const _005b9c86 = () => interopDefault(import('../pages/supplier/_supplierId/update.vue' /* webpackChunkName: "pages/supplier/_supplierId/update" */))
const _f59df072 = () => interopDefault(import('../pages/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/tenant/_tenantId/documents" */))
const _78ef6208 = () => interopDefault(import('../pages/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/tenant/_tenantId/informations" */))
const _3553693a = () => interopDefault(import('../pages/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/tenant/_tenantId/payment-methods" */))
const _0826a86c = () => interopDefault(import('../pages/tenant/_tenantId/update.vue' /* webpackChunkName: "pages/tenant/_tenantId/update" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _232e9828,
    name: "dashboard___fr"
  }, {
    path: "/en",
    component: _301b35ba,
    name: "index___en"
  }, {
    path: "/onboarding",
    component: _c6c711c6,
    name: "onboarding___fr"
  }, {
    path: "/oskar-courses",
    component: _19a5b6b7,
    name: "oskar-courses___fr"
  }, {
    path: "/settings",
    component: _e6e65e36,
    name: "settings___fr"
  }, {
    path: "/unauthenticated-signing-page",
    component: _7c4af210,
    name: "unauthenticated-signing-page___fr"
  }, {
    path: "/auth/login",
    component: _15c7fec1,
    name: "auth-login___fr"
  }, {
    path: "/auth/logout",
    component: _1f6e3c3c,
    name: "auth-logout___fr"
  }, {
    path: "/auth/renew-password",
    component: _32a1849a,
    name: "auth-renew-password___fr"
  }, {
    path: "/auth/reset-password",
    component: _ae21939e,
    name: "auth-reset-password___fr"
  }, {
    path: "/auth/switch-account",
    component: _1bbdb028,
    name: "auth-switch-account___fr"
  }, {
    path: "/dashboard/bank-accounts",
    component: _403e032d,
    name: "dashboard-bank-accounts___fr"
  }, {
    path: "/dashboard/landlords",
    component: _04914e2e,
    name: "dashboard-landlords___fr"
  }, {
    path: "/dashboard/notifications",
    component: _572f6b3e,
    name: "dashboard-notifications___fr"
  }, {
    path: "/dashboard/properties",
    component: _26c64166,
    name: "dashboard-properties___fr"
  }, {
    path: "/dashboard/suppliers",
    component: _99582406,
    name: "dashboard-suppliers___fr"
  }, {
    path: "/dashboard/tenants",
    component: _727a315f,
    name: "dashboard-tenants___fr"
  }, {
    path: "/dashboard/unpaid-costs",
    component: _67bee778,
    name: "dashboard-unpaid-costs___fr"
  }, {
    path: "/dashboard/unpaid-rent",
    component: _52c99f12,
    name: "dashboard-unpaid-rent___fr"
  }, {
    path: "/en/dashboard",
    component: _232e9828,
    name: "dashboard___en"
  }, {
    path: "/en/onboarding",
    component: _c6c711c6,
    name: "onboarding___en"
  }, {
    path: "/en/oskar-courses",
    component: _19a5b6b7,
    name: "oskar-courses___en"
  }, {
    path: "/en/settings",
    component: _e6e65e36,
    name: "settings___en"
  }, {
    path: "/en/unauthenticated-signing-page",
    component: _7c4af210,
    name: "unauthenticated-signing-page___en"
  }, {
    path: "/error/404",
    component: _16d0e502,
    name: "error-404___fr"
  }, {
    path: "/onboarding/confirm_invitation",
    component: _79750b3d,
    name: "onboarding-confirm_invitation___fr"
  }, {
    path: "/onboarding/demo",
    component: _7367cfe8,
    name: "onboarding-demo___fr"
  }, {
    path: "/settings/company-details",
    component: _cfeb8176,
    name: "settings-company-details___fr"
  }, {
    path: "/swan/callback",
    component: _666195d4,
    name: "swan-callback___fr"
  }, {
    path: "/swan/consent-callback",
    component: _5177e4b2,
    name: "swan-consent-callback___fr"
  }, {
    path: "/dashboard/accounting/listing",
    component: _a81b7cb8,
    name: "dashboard-accounting-listing___fr",
    children: [{
      path: "accounting-accounts",
      component: _20b653b3,
      name: "dashboard-accounting-listing-accounting-accounts___fr"
    }, {
      path: "accounting-entries",
      component: _657c675a,
      name: "dashboard-accounting-listing-accounting-entries___fr"
    }, {
      path: "accounting-entry-sets",
      component: _2b78dc59,
      name: "dashboard-accounting-listing-accounting-entry-sets___fr"
    }, {
      path: "accounting-journals",
      component: _93b6762e,
      name: "dashboard-accounting-listing-accounting-journals___fr"
    }]
  }, {
    path: "/en/auth/login",
    component: _15c7fec1,
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _1f6e3c3c,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/renew-password",
    component: _32a1849a,
    name: "auth-renew-password___en"
  }, {
    path: "/en/auth/reset-password",
    component: _ae21939e,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/switch-account",
    component: _1bbdb028,
    name: "auth-switch-account___en"
  }, {
    path: "/en/dashboard/bank-accounts",
    component: _403e032d,
    name: "dashboard-bank-accounts___en"
  }, {
    path: "/en/dashboard/landlords",
    component: _04914e2e,
    name: "dashboard-landlords___en"
  }, {
    path: "/en/dashboard/notifications",
    component: _572f6b3e,
    name: "dashboard-notifications___en"
  }, {
    path: "/en/dashboard/properties",
    component: _26c64166,
    name: "dashboard-properties___en"
  }, {
    path: "/en/dashboard/suppliers",
    component: _99582406,
    name: "dashboard-suppliers___en"
  }, {
    path: "/en/dashboard/tenants",
    component: _727a315f,
    name: "dashboard-tenants___en"
  }, {
    path: "/en/dashboard/unpaid-costs",
    component: _67bee778,
    name: "dashboard-unpaid-costs___en"
  }, {
    path: "/en/dashboard/unpaid-rent",
    component: _52c99f12,
    name: "dashboard-unpaid-rent___en"
  }, {
    path: "/en/error/404",
    component: _16d0e502,
    name: "error-404___en"
  }, {
    path: "/en/onboarding/confirm_invitation",
    component: _79750b3d,
    name: "onboarding-confirm_invitation___en"
  }, {
    path: "/en/onboarding/demo",
    component: _7367cfe8,
    name: "onboarding-demo___en"
  }, {
    path: "/en/settings/company-details",
    component: _cfeb8176,
    name: "settings-company-details___en"
  }, {
    path: "/en/swan/callback",
    component: _666195d4,
    name: "swan-callback___en"
  }, {
    path: "/en/swan/consent-callback",
    component: _5177e4b2,
    name: "swan-consent-callback___en"
  }, {
    path: "/viewer/CGU/cgu",
    component: _1b4083f5,
    name: "viewer-CGU-cgu___fr"
  }, {
    path: "/workflow/account-statement/create",
    component: _169e6d46,
    name: "workflow-account-statement-create___fr"
  }, {
    path: "/workflow/landlord/create",
    component: _06b445fa,
    name: "workflow-landlord-create___fr"
  }, {
    path: "/workflow/property/create",
    component: _447faf36,
    name: "workflow-property-create___fr"
  }, {
    path: "/workflow/supplier/create",
    component: _39c9bc0e,
    name: "workflow-supplier-create___fr"
  }, {
    path: "/workflow/tenant/create",
    component: _0fb92030,
    name: "workflow-tenant-create___fr"
  }, {
    path: "/en/dashboard/accounting/listing",
    component: _a81b7cb8,
    name: "dashboard-accounting-listing___en",
    children: [{
      path: "accounting-accounts",
      component: _20b653b3,
      name: "dashboard-accounting-listing-accounting-accounts___en"
    }, {
      path: "accounting-entries",
      component: _657c675a,
      name: "dashboard-accounting-listing-accounting-entries___en"
    }, {
      path: "accounting-entry-sets",
      component: _2b78dc59,
      name: "dashboard-accounting-listing-accounting-entry-sets___en"
    }, {
      path: "accounting-journals",
      component: _93b6762e,
      name: "dashboard-accounting-listing-accounting-journals___en"
    }]
  }, {
    path: "/en/viewer/CGU/cgu",
    component: _1b4083f5,
    name: "viewer-CGU-cgu___en"
  }, {
    path: "/en/workflow/account-statement/create",
    component: _169e6d46,
    name: "workflow-account-statement-create___en"
  }, {
    path: "/en/workflow/landlord/create",
    component: _06b445fa,
    name: "workflow-landlord-create___en"
  }, {
    path: "/en/workflow/property/create",
    component: _447faf36,
    name: "workflow-property-create___en"
  }, {
    path: "/en/workflow/supplier/create",
    component: _39c9bc0e,
    name: "workflow-supplier-create___en"
  }, {
    path: "/en/workflow/tenant/create",
    component: _0fb92030,
    name: "workflow-tenant-create___en"
  }, {
    path: "/en/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _7c26a332,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___en"
  }, {
    path: "/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _7c26a332,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___fr"
  }, {
    path: "/en/dashboard/landlord/:landlordId",
    component: _352b6c68,
    name: "dashboard-landlord-landlordId___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId",
    component: _0cf3abfc,
    name: "dashboard-tenant-tenantId___en"
  }, {
    path: "/en/viewer/file/:fileId?",
    component: _d19fc49e,
    name: "viewer-file-fileId___en"
  }, {
    path: "/en/viewer/inventory/:inventoryId?",
    component: _3f3cba99,
    name: "viewer-inventory-inventoryId___en"
  }, {
    path: "/en/viewer/management-report/:managementReportId?",
    component: _5d7e15e2,
    name: "viewer-management-report-managementReportId___en"
  }, {
    path: "/en/viewer/mandate/:mandateId?",
    component: _28492c19,
    name: "viewer-mandate-mandateId___en"
  }, {
    path: "/en/viewer/payment/:paymentId?",
    component: _13de39d9,
    name: "viewer-payment-paymentId___en"
  }, {
    path: "/en/viewer/rent/:rentId?",
    component: _e8bd982a,
    name: "viewer-rent-rentId___en"
  }, {
    path: "/en/viewer/rental/:rentalId?",
    component: _314f09fe,
    name: "viewer-rental-rentalId___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/documents",
    component: _0eedf764,
    name: "dashboard-landlord-landlordId-documents___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/informations",
    component: _25034421,
    name: "dashboard-landlord-landlordId-informations___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/management-reports",
    component: _737dad0f,
    name: "dashboard-landlord-landlordId-management-reports___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/properties",
    component: _2e8a99e6,
    name: "dashboard-landlord-landlordId-properties___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/documents",
    component: _2c3f59e2,
    name: "dashboard-tenant-tenantId-documents___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/informations",
    component: _1717d3e6,
    name: "dashboard-tenant-tenantId-informations___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/payment-methods",
    component: _2d247b15,
    name: "dashboard-tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rentals",
    component: _6442700e,
    name: "dashboard-tenant-tenantId-rentals___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rents",
    component: _727ce8f8,
    name: "dashboard-tenant-tenantId-rents___en"
  }, {
    path: "/en/workflow/property/:propertyId?/cost/create",
    component: _440be7c5,
    name: "workflow-property-propertyId-cost-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/inventory/create",
    component: _57cb0ed8,
    name: "workflow-property-propertyId-inventory-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/create.old",
    component: _df85a4b6,
    name: "workflow-property-propertyId-mandate-create.old___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/form",
    component: _c0793a98,
    name: "workflow-property-propertyId-mandate-form___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rent/create",
    component: _0cae20d9,
    name: "workflow-property-propertyId-rent-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/create",
    component: _0d938364,
    name: "workflow-property-propertyId-rental-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/tenant-cost/create",
    component: _cfcdb738,
    name: "workflow-property-propertyId-tenant-cost-create___en"
  }, {
    path: "/en/viewer/property/:propertyId?/cost/:costId?",
    component: _633ac3c1,
    name: "viewer-property-propertyId-cost-costId___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _1c2f6629,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___en"
  }, {
    path: "/dashboard/landlord/:landlordId",
    component: _352b6c68,
    name: "dashboard-landlord-landlordId___fr"
  }, {
    path: "/dashboard/tenant/:tenantId",
    component: _0cf3abfc,
    name: "dashboard-tenant-tenantId___fr"
  }, {
    path: "/en/landlord/:landlordId",
    component: _e3c5cb66,
    name: "landlord-landlordId___en"
  }, {
    path: "/en/property/:propertyId",
    component: _f03efc12,
    name: "property-propertyId___en"
  }, {
    path: "/en/supplier/:supplierId",
    component: _90c7c7b6,
    name: "supplier-supplierId___en"
  }, {
    path: "/en/tenant/:tenantId",
    component: _01ca1461,
    name: "tenant-tenantId___en"
  }, {
    path: "/viewer/file/:fileId?",
    component: _d19fc49e,
    name: "viewer-file-fileId___fr"
  }, {
    path: "/viewer/inventory/:inventoryId?",
    component: _3f3cba99,
    name: "viewer-inventory-inventoryId___fr"
  }, {
    path: "/viewer/management-report/:managementReportId?",
    component: _5d7e15e2,
    name: "viewer-management-report-managementReportId___fr"
  }, {
    path: "/viewer/mandate/:mandateId?",
    component: _28492c19,
    name: "viewer-mandate-mandateId___fr"
  }, {
    path: "/viewer/payment/:paymentId?",
    component: _13de39d9,
    name: "viewer-payment-paymentId___fr"
  }, {
    path: "/viewer/rent/:rentId?",
    component: _e8bd982a,
    name: "viewer-rent-rentId___fr"
  }, {
    path: "/viewer/rental/:rentalId?",
    component: _314f09fe,
    name: "viewer-rental-rentalId___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/documents",
    component: _0eedf764,
    name: "dashboard-landlord-landlordId-documents___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/informations",
    component: _25034421,
    name: "dashboard-landlord-landlordId-informations___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/management-reports",
    component: _737dad0f,
    name: "dashboard-landlord-landlordId-management-reports___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/properties",
    component: _2e8a99e6,
    name: "dashboard-landlord-landlordId-properties___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/documents",
    component: _2c3f59e2,
    name: "dashboard-tenant-tenantId-documents___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/informations",
    component: _1717d3e6,
    name: "dashboard-tenant-tenantId-informations___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/payment-methods",
    component: _2d247b15,
    name: "dashboard-tenant-tenantId-payment-methods___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rentals",
    component: _6442700e,
    name: "dashboard-tenant-tenantId-rentals___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rents",
    component: _727ce8f8,
    name: "dashboard-tenant-tenantId-rents___fr"
  }, {
    path: "/en/landlord/:landlordId?/documents",
    component: _037737b3,
    name: "landlord-landlordId-documents___en"
  }, {
    path: "/en/landlord/:landlordId?/informations",
    component: _22b21a9c,
    name: "landlord-landlordId-informations___en"
  }, {
    path: "/en/landlord/:landlordId?/management-reports",
    component: _525b096c,
    name: "landlord-landlordId-management-reports___en"
  }, {
    path: "/en/landlord/:landlordId?/payouts",
    component: _36a506c8,
    name: "landlord-landlordId-payouts___en"
  }, {
    path: "/en/landlord/:landlordId?/update",
    component: _0c0b3944,
    name: "landlord-landlordId-update___en"
  }, {
    path: "/en/property/:propertyId?/contracts",
    component: _c9024674,
    name: "property-propertyId-contracts___en"
  }, {
    path: "/en/property/:propertyId?/costs",
    component: _ae126d2a,
    name: "property-propertyId-costs___en"
  }, {
    path: "/en/property/:propertyId?/documents",
    component: _188aba5d,
    name: "property-propertyId-documents___en"
  }, {
    path: "/en/property/:propertyId?/rents",
    component: _41ae617f,
    name: "property-propertyId-rents___en"
  }, {
    path: "/en/property/:propertyId?/update",
    component: _38a3f0f4,
    name: "property-propertyId-update___en"
  }, {
    path: "/en/supplier/:supplierId?/informations",
    component: _b9045478,
    name: "supplier-supplierId-informations___en"
  }, {
    path: "/en/supplier/:supplierId?/update",
    component: _005b9c86,
    name: "supplier-supplierId-update___en"
  }, {
    path: "/en/tenant/:tenantId?/documents",
    component: _f59df072,
    name: "tenant-tenantId-documents___en"
  }, {
    path: "/en/tenant/:tenantId?/informations",
    component: _78ef6208,
    name: "tenant-tenantId-informations___en"
  }, {
    path: "/en/tenant/:tenantId?/payment-methods",
    component: _3553693a,
    name: "tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/tenant/:tenantId?/update",
    component: _0826a86c,
    name: "tenant-tenantId-update___en"
  }, {
    path: "/workflow/property/:propertyId?/cost/create",
    component: _440be7c5,
    name: "workflow-property-propertyId-cost-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/inventory/create",
    component: _57cb0ed8,
    name: "workflow-property-propertyId-inventory-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/create.old",
    component: _df85a4b6,
    name: "workflow-property-propertyId-mandate-create.old___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/form",
    component: _c0793a98,
    name: "workflow-property-propertyId-mandate-form___fr"
  }, {
    path: "/workflow/property/:propertyId?/rent/create",
    component: _0cae20d9,
    name: "workflow-property-propertyId-rent-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/create",
    component: _0d938364,
    name: "workflow-property-propertyId-rental-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/tenant-cost/create",
    component: _cfcdb738,
    name: "workflow-property-propertyId-tenant-cost-create___fr"
  }, {
    path: "/viewer/property/:propertyId?/cost/:costId?",
    component: _633ac3c1,
    name: "viewer-property-propertyId-cost-costId___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _1c2f6629,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___fr"
  }, {
    path: "/landlord/:landlordId",
    component: _e3c5cb66,
    name: "landlord-landlordId___fr"
  }, {
    path: "/property/:propertyId",
    component: _f03efc12,
    name: "property-propertyId___fr"
  }, {
    path: "/supplier/:supplierId",
    component: _90c7c7b6,
    name: "supplier-supplierId___fr"
  }, {
    path: "/tenant/:tenantId",
    component: _01ca1461,
    name: "tenant-tenantId___fr"
  }, {
    path: "/landlord/:landlordId?/documents",
    component: _037737b3,
    name: "landlord-landlordId-documents___fr"
  }, {
    path: "/landlord/:landlordId?/informations",
    component: _22b21a9c,
    name: "landlord-landlordId-informations___fr"
  }, {
    path: "/landlord/:landlordId?/management-reports",
    component: _525b096c,
    name: "landlord-landlordId-management-reports___fr"
  }, {
    path: "/landlord/:landlordId?/payouts",
    component: _36a506c8,
    name: "landlord-landlordId-payouts___fr"
  }, {
    path: "/landlord/:landlordId?/update",
    component: _0c0b3944,
    name: "landlord-landlordId-update___fr"
  }, {
    path: "/property/:propertyId?/contracts",
    component: _c9024674,
    name: "property-propertyId-contracts___fr"
  }, {
    path: "/property/:propertyId?/costs",
    component: _ae126d2a,
    name: "property-propertyId-costs___fr"
  }, {
    path: "/property/:propertyId?/documents",
    component: _188aba5d,
    name: "property-propertyId-documents___fr"
  }, {
    path: "/property/:propertyId?/rents",
    component: _41ae617f,
    name: "property-propertyId-rents___fr"
  }, {
    path: "/property/:propertyId?/update",
    component: _38a3f0f4,
    name: "property-propertyId-update___fr"
  }, {
    path: "/supplier/:supplierId?/informations",
    component: _b9045478,
    name: "supplier-supplierId-informations___fr"
  }, {
    path: "/supplier/:supplierId?/update",
    component: _005b9c86,
    name: "supplier-supplierId-update___fr"
  }, {
    path: "/tenant/:tenantId?/documents",
    component: _f59df072,
    name: "tenant-tenantId-documents___fr"
  }, {
    path: "/tenant/:tenantId?/informations",
    component: _78ef6208,
    name: "tenant-tenantId-informations___fr"
  }, {
    path: "/tenant/:tenantId?/payment-methods",
    component: _3553693a,
    name: "tenant-tenantId-payment-methods___fr"
  }, {
    path: "/tenant/:tenantId?/update",
    component: _0826a86c,
    name: "tenant-tenantId-update___fr"
  }, {
    path: "/",
    component: _301b35ba,
    name: "index___fr"
  }],

  parseQuery: function(queryString) {
            return require('qs').parse(queryString)
        },
  stringifyQuery: function(object) {
            var queryString = require('qs').stringify(object)
            return queryString ? '?' + queryString : ''
        },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
