<template>

    <div class="duration">

        <template v-if="durationMoment.years()">{{ durationMoment.years() }} {{ $t('years') }}</template>

        <template v-if="durationMoment.months()">{{ durationMoment.months() }} {{ $t('months') }}</template>

        <template v-if="durationMoment.days() && !durationMoment.years()">{{ durationMoment.days() }} {{ $t('days') }}</template>

    </div>

</template>

<script>
    export default {
        props: {
            duration: {
                type: Number,
                required: true
            }
        },

        computed: {
            durationMoment() {
                return this.$moment.duration(this.duration, 'days')
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "years": "ans",
            "months": "mois",
            "days": "jours"
        }
    }
</i18n>