<template>

    <form-group class="bank-account-inputs-group" :name="name" :rules="rules">

        <payment-method-element class="bank-account-elements" type="bank_account" :paymentMethod="bankAccount" :active="bankAccountIndex === key" @edit="editPayoutBankAccount(key)" @delete="deleteBankAccount(key)" v-for="(bankAccount, key) in value" :key="key" v-if="options.mode === 'payment-methods'" />

        <div class="bank-accounts-cards" v-if="options.mode === 'bank-account-cards'">

            <bank-account-card class="bank-account-card" :account="bankAccount" :border="true" :active="bankAccount.default || bankAccountIndex === key" v-for="(bankAccount, key) in value" :key="key" @click.native="editBankAccount(key)" />

        </div>

        <payment-method-element type="bank_account" :editAction="$t('create_account')" @edit="initBankAccountForm" v-if="!editMode"/>

        <hr class="separator" v-if="editMode && value.length">

        <step-nested-form :title="$t('new_bank_account')" :itemCount="updateMode ? bankAccountIndex : value.length" :limit="5" :onSubmit="addOrUpdateBankAccount" :onDelete="deleteBankAccount" :isDeleteToogle="!bankAccountMethodDefault" @close="resetForm" :open="editMode" :updateMode="updateMode" :fields="{ bankAccountMethodAccountName, bankAccountMethodHolderName, bankAccountMethodBankName, bankAccountMethodIban, bankAccountMethodBic, bankAccountMethodDefault }">

            <text-input class="fullsize" name="bank_account_account_name" :label="$t('bank_account_account_name')" :placeholder="$t('bank_account_account_name_placeholder')" v-model="bankAccountMethodAccountName" :rules="'required'" />

            <text-input class="fullsize" name="bank_account_holder_name" :label="$t('bank_account_holder_name')" :placeholder="$t('bank_account_holder_name_placeholder')" v-model="bankAccountMethodHolderName" :rules="'required'" />

            <text-input class="fullsize" name="bank_account_bank_name" :label="$t('bank_account_bank_name')" :placeholder="$t('bank_account_bank_name_placeholder')" v-model="bankAccountMethodBankName" :rules="'required'" />

            <iban-input class="fullsize" name="bank_account_number" :label="$t('bank_account_number')" :placeholder="$t('bank_account_number_placeholder')" v-model="bankAccountMethodIban" ref="iban" :rules="'required'" />

            <text-input class="fullsize" name="bank_account_bic" :label="$t('bank_account_bic')" :placeholder="$t('bank_account_bic_placeholder')" v-model="bankAccountMethodBic" :rules="'required'" />

            <check-box-input class="fullsize" name="bank_account_default" :label="$t('bank_account_default')" :placeholder="$t('bank_account_default_placeholder')" v-model="bankAccountMethodDefault" v-if="value.length >= 1"/>

        </step-nested-form>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import StepGroup from '~/components/workflow/step-group'
    import PaymentMethodElement from '~/components/payment-method-element'
    import BankAccountCard from '~/components/bank-account-card'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            PaymentMethodElement,
            BankAccountCard,
            FormGroup,
            StepGroup
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            rules: {
                type: String,
                required: false
            },
            value: {
                type: Array,
                required: true
            },
            options: {
                type: Object,
                default() {
                    return {
                        mode: 'payment-methods'
                    }
                }
            }
        },

        data() {
            return {
                editMode: false,
                updateMode: false,
                bankAccountIndex: -1,
            }
        },

        mounted() {
            if (!this.value.length) this.initBankAccountForm(false)
        },

        methods: {
            initBankAccountForm() {

                this.resetForm(false)

                this.bankAccountIndex = -1

                this.editMode = true
            },

            resetForm(close) {

                this.$store.commit(`bank-account/resetDefaultState`)

                if (close) this.editMode = false

                this.updateMode = false
            },

            addOrUpdateBankAccount() {
                let valueCopy = [...this.value]

                let newBankAccount = {
                    account_name: this.bankAccountMethodAccountName,
                    holder_name: this.bankAccountMethodHolderName,
                    bank_name: this.bankAccountMethodBankName,
                    iban: this.bankAccountMethodIban,
                    bic: this.bankAccountMethodBic,
                    default: this.bankAccountMethodDefault,
                }

                if (this.bankAccountType !== '') newBankAccount['_jv'] = {
                    type: this.bankAccountType,
                    id: this.bankAccountId
                }

                if (this.bankAccountMethodDefault) valueCopy = valueCopy.map(bankAccount => Object.assign(bankAccount, { default: false }))

                if (this.bankAccountIndex !== -1) {

                    valueCopy.splice(this.bankAccountIndex, 1, newBankAccount)

                    this.bankAccountIndex = -1

                    this.editMode = false

                } else {

                  valueCopy.push(newBankAccount)
                }

                this.$emit('input', valueCopy)
            },

            async editBankAccount(key) {

                this.resetForm(false)

                await this.$store.dispatch(`bank-account/fillBankAccount`, this.value[key])

                this.bankAccountIndex = key

                this.editMode = true

                this.updateMode = true
            },

            async editPayoutBankAccount(key) {

                this.resetForm(false)

                await this.$store.dispatch(`bank-account/fillPayoutBankAccount`, this.value[key])

                this.bankAccountIndex = key

                this.editMode = true

                this.updateMode = true
            },

            async deleteBankAccount(key) {
                try {

                    if (this.value[key]['_jv'].type === 'bank-accounts') {

                        await this.$store.dispatch(`bank-account/deleteBankAccount`, this.value[key]['_jv'].id)
                    }

                    else if (this.value[key]['_jv'].type === 'payout-bank-accounts') {

                        await this.$store.dispatch(`bank-account/deletePayoutBankAccount`, this.value[key]['_jv'].id)
                    }

                    let valueCopy = [...this.value]

                    valueCopy.splice(this.bankAccountIndex, 1)

                    this.$emit('input', valueCopy)

                } catch(error) {

                    throw error

                } finally {

                    this.resetForm()
                }
            },
        },

        computed: {
            bankAccountMethodAccountName: {
                get() {
                    return this.$store.getters[`bank-account/getAccountName`]
                },
                set(newValue) {
                    return this.$store.commit(`bank-account/setAccountName`, newValue)
                }
            },
            bankAccountMethodHolderName: {
                get() {
                    return this.$store.getters[`bank-account/getHolderName`]
                },
                set(newValue) {
                    return this.$store.commit(`bank-account/setHolderName`, newValue)
                }
            },

            bankAccountMethodBankName: {
                get() {
                    return this.$store.getters[`bank-account/getBankName`]
                },
                set(newValue) {
                    return this.$store.commit(`bank-account/setBankName`, newValue)
                }
            },

            bankAccountMethodIban: {
                get() {
                    return this.$store.getters[`bank-account/getIban`]
                },
                set(newValue) {
                    return this.$store.commit(`bank-account/setIban`, newValue)
                }
            },

            bankAccountMethodBic: {
                get() {
                    return this.$store.getters[`bank-account/getBic`]
                },
                set(newValue) {
                    return this.$store.commit(`bank-account/setBic`, newValue)
                }
            },

            bankAccountMethodDefault: {
                get() {
                    return this.$store.getters[`bank-account/getDefault`]
                },
                set(newValue) {
                    return this.$store.commit(`bank-account/setDefault`, newValue)
                }
            },

            ...mapGetters({
                bankAccountType: 'bank-account/getType',
                bankAccountId: 'bank-account/getId'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "new_bank_account": "Ajouter un nouveau compte bancaire",
            "bank_account": "Compte bancaire",
            "bank_account_account_name": "Intitulé du compte",
            "bank_account_account_name_placeholder": "Compte séquestre",
            "bank_account_holder_name": "Titulaire du compte",
            "bank_account_holder_name_placeholder": "Thierry Dupont",
            "bank_account_bank_name": "Nom d'établissement",
            "bank_account_bank_name_placeholder": "BNP Paribas",
            "bank_account_number": "IBAN",
            "bank_account_number_placeholder": "FR76 3000 3212 4343 2100 6000 543",
            "bank_account_bic": "BIC",
            "bank_account_bic_placeholder": "SXPYDEHH",
            "create_account": "Ajouter un compte bancaire",
            "bank_account_default": "Définir comme compte bancaire par défaut"
        }
    }
</i18n>

<style lang="scss" scoped>
    $separator-color: rgb(242, 242, 242);

    .bank-account-inputs-group {
        .bank-account-elements {
            margin-bottom: 12px;
        }

        .bank-accounts-cards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 12px;
            margin-bottom: 12px;

            .bank-account-card {
                width: auto;
            }
        }

        .separator {
            margin: 27px auto;
            width: 72px;
            height: 1px;
            background: #F2F2F2;
            border-radius: 5px;
            border: none;
        }

        .button {
            margin-top: 12px;
        }
    }
</style>
