import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_89753a42 from 'nuxt_plugin_sentryserver_89753a42' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_558bf157 from 'nuxt_plugin_sentryclient_558bf157' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginutils_2f191980 from 'nuxt_plugin_pluginutils_2f191980' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_f98d726a from 'nuxt_plugin_pluginrouting_f98d726a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_4a6b1084 from 'nuxt_plugin_pluginmain_4a6b1084' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_toast_68cb8a6c from 'nuxt_plugin_toast_68cb8a6c' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_012c23ca from 'nuxt_plugin_axios_012c23ca' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_moment_07013246 from 'nuxt_plugin_moment_07013246' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_axiosinstance_14d1fdb5 from 'nuxt_plugin_axiosinstance_14d1fdb5' // Source: ../plugins/axios-instance.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_importinputcomponents_3556742a from 'nuxt_plugin_importinputcomponents_3556742a' // Source: ../plugins/import-input-components (mode: 'all')
import nuxt_plugin_importstepformcomponents_769836b0 from 'nuxt_plugin_importstepformcomponents_769836b0' // Source: ../plugins/import-step-form-components (mode: 'all')
import nuxt_plugin_importtablelistcomponents_2fb0e5f4 from 'nuxt_plugin_importtablelistcomponents_2fb0e5f4' // Source: ../plugins/import-table-list-components (mode: 'all')
import nuxt_plugin_globalerrorhandler_85522ff4 from 'nuxt_plugin_globalerrorhandler_85522ff4' // Source: ../plugins/global-error-handler.js (mode: 'client')
import nuxt_plugin_hellosign_63c5f87e from 'nuxt_plugin_hellosign_63c5f87e' // Source: ../plugins/hellosign.js (mode: 'client')
import nuxt_plugin_hubspottracking_2c2e8354 from 'nuxt_plugin_hubspottracking_2c2e8354' // Source: ../plugins/hubspot-tracking.js (mode: 'client')
import nuxt_plugin_jsonapivuex_3988bc34 from 'nuxt_plugin_jsonapivuex_3988bc34' // Source: ../plugins/jsonapi-vuex.js (mode: 'all')
import nuxt_plugin_veevalidate_1a0c1998 from 'nuxt_plugin_veevalidate_1a0c1998' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_sentry_e47869fc from 'nuxt_plugin_sentry_e47869fc' // Source: ../plugins/sentry.js (mode: 'all')
import nuxt_plugin_stripejs_e864c5f8 from 'nuxt_plugin_stripejs_e864c5f8' // Source: ../plugins/stripe-js.js (mode: 'all')
import nuxt_plugin_vuechart_7d19cf94 from 'nuxt_plugin_vuechart_7d19cf94' // Source: ../plugins/vue-chart.js (mode: 'client')
import nuxt_plugin_vuepdfembed_366df154 from 'nuxt_plugin_vuepdfembed_366df154' // Source: ../plugins/vue-pdf-embed.js (mode: 'client')
import nuxt_plugin_vuefunctionalcalendar_145c014c from 'nuxt_plugin_vuefunctionalcalendar_145c014c' // Source: ../plugins/vue-functional-calendar.js (mode: 'client')
import nuxt_plugin_vclickoutside_12eb1bf8 from 'nuxt_plugin_vclickoutside_12eb1bf8' // Source: ../plugins/v-click-outside.js (mode: 'all')
import nuxt_plugin_vuethemask_0a766a32 from 'nuxt_plugin_vuethemask_0a766a32' // Source: ../plugins/vue-the-mask.js (mode: 'all')
import nuxt_plugin_vuescrollto_44ce9a1c from 'nuxt_plugin_vuescrollto_44ce9a1c' // Source: ../plugins/vue-scrollto.js (mode: 'client')
import nuxt_plugin_auth_45c60bb5 from 'nuxt_plugin_auth_45c60bb5' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Oskar | Logiciel de gestion locative automatisé","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Solution innovante full-web pour tous les professionnels de la gestion locative, les agents immobiliers et les conseillers en investissement locatif."}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.png"}],"script":[{"src":"https:\u002F\u002Fmaps.googleapis.com\u002Fmaps\u002Fapi\u002Fjs?key=AIzaSyDPR87LCUCPBRYeNWoCKMD4bYjGOCVNzS8&libraries=places","defer":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_89753a42 === 'function') {
    await nuxt_plugin_sentryserver_89753a42(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_558bf157 === 'function') {
    await nuxt_plugin_sentryclient_558bf157(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_2f191980 === 'function') {
    await nuxt_plugin_pluginutils_2f191980(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_f98d726a === 'function') {
    await nuxt_plugin_pluginrouting_f98d726a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_4a6b1084 === 'function') {
    await nuxt_plugin_pluginmain_4a6b1084(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_68cb8a6c === 'function') {
    await nuxt_plugin_toast_68cb8a6c(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_012c23ca === 'function') {
    await nuxt_plugin_axios_012c23ca(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_07013246 === 'function') {
    await nuxt_plugin_moment_07013246(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosinstance_14d1fdb5 === 'function') {
    await nuxt_plugin_axiosinstance_14d1fdb5(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (typeof nuxt_plugin_importinputcomponents_3556742a === 'function') {
    await nuxt_plugin_importinputcomponents_3556742a(app.context, inject)
  }

  if (typeof nuxt_plugin_importstepformcomponents_769836b0 === 'function') {
    await nuxt_plugin_importstepformcomponents_769836b0(app.context, inject)
  }

  if (typeof nuxt_plugin_importtablelistcomponents_2fb0e5f4 === 'function') {
    await nuxt_plugin_importtablelistcomponents_2fb0e5f4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalerrorhandler_85522ff4 === 'function') {
    await nuxt_plugin_globalerrorhandler_85522ff4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hellosign_63c5f87e === 'function') {
    await nuxt_plugin_hellosign_63c5f87e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hubspottracking_2c2e8354 === 'function') {
    await nuxt_plugin_hubspottracking_2c2e8354(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonapivuex_3988bc34 === 'function') {
    await nuxt_plugin_jsonapivuex_3988bc34(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c1998 === 'function') {
    await nuxt_plugin_veevalidate_1a0c1998(app.context, inject)
  }

  if (typeof nuxt_plugin_sentry_e47869fc === 'function') {
    await nuxt_plugin_sentry_e47869fc(app.context, inject)
  }

  if (typeof nuxt_plugin_stripejs_e864c5f8 === 'function') {
    await nuxt_plugin_stripejs_e864c5f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuechart_7d19cf94 === 'function') {
    await nuxt_plugin_vuechart_7d19cf94(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuepdfembed_366df154 === 'function') {
    await nuxt_plugin_vuepdfembed_366df154(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuefunctionalcalendar_145c014c === 'function') {
    await nuxt_plugin_vuefunctionalcalendar_145c014c(app.context, inject)
  }

  if (typeof nuxt_plugin_vclickoutside_12eb1bf8 === 'function') {
    await nuxt_plugin_vclickoutside_12eb1bf8(app.context, inject)
  }

  if (typeof nuxt_plugin_vuethemask_0a766a32 === 'function') {
    await nuxt_plugin_vuethemask_0a766a32(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_44ce9a1c === 'function') {
    await nuxt_plugin_vuescrollto_44ce9a1c(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_45c60bb5 === 'function') {
    await nuxt_plugin_auth_45c60bb5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
